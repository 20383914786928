import { render, staticRenderFns } from "./email-reports-hotel.page.vue?vue&type=template&id=c54f8d44&scoped=true&"
import script from "./email-reports-hotel.page.vue?vue&type=script&lang=ts&"
export * from "./email-reports-hotel.page.vue?vue&type=script&lang=ts&"
import style0 from "./email-reports-hotel.page.vue?vue&type=style&index=0&id=c54f8d44&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c54f8d44",
  null
  
)

export default component.exports